$rootPath: "/v40/23179";
@import "scss/variables";

// Override variables in the default theme
$brand-primary: #313131;
$brand-info: #313131;
$input-border-focus: $brand-primary;

@import "scss/bootstrap-variables";
@import "scss/mixins";
@import "scss/mixins/labels";

@import "../../../../node_modules/@bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
@import "scss/bootstrap-override";
@import "scss/bootstrap-datetimepicker-override";
@import "scss/bootstrap-devbridge-autocomplete-override";

@import "scss/mobile";
